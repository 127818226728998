export const REGISTER = "REGISTER";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SET_SERVER_URL = "SET_SERVER_URL";
export const SET_SELF_HOSTED = "SET_SELF_HOSTED";
export const SET_SERVER_INFO = "SET_SERVER_INFO";
export const OPEN_GUIDE = "OPEN_GUIDE";
export const CLOSE_GUIDE = "CLOSE_GUIDE";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_THEME = "SET_THEME";

export const SET_VOCAB_ACTIVE = "SET_VOCAB_ACTIVE";
export const SET_VOCAB_ACTIVATE = "SET_VOCAB_ACTIVATE";
export const SET_GROUP_ACTIVATE = "SET_GROUP_ACTIVATE";

export const SET_TABLE_PAGE_SIZE = "SET_TABLE_PAGE_SIZE";

export const SET_LEARNED_PACKAGE = "SET_LEARNED_PACKAGE";

export const SET_QUERY_CORRECT = "SET_QUERY_CORRECT";
export const SET_QUERY_WRONG = "SET_QUERY_WRONG";
export const SET_ACTUAL_PROGRESS = "SET_ACTUAL_PROGRESS";
export const CLEAR_QUERY = "CLEAR_QUERY";
export const SET_GROUP_IDS = "SET_GROUP_IDS";
